<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row">
      <div class="col-md-12">
        <filter-regional
          v-if="!isDistributor && !isDistributor_khusus"
          class="mr-2"
          v-model:value="region"
        ></filter-regional>
        <filter-province
          v-if="!isDistributor && !isDistributor_khusus"
          class="mr-2 mt-2"
          v-model:value="provinsi"
          v-model:region="region"
        ></filter-province>
        <filter-area
          v-if="!isDistributor && !isDistributor_khusus"
          class="mt-2"
          v-model:value="area"
          v-model:provinsi="provinsi"
          v-model:region="region"
        ></filter-area>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <filter-kabupaten-kota
          v-if="!isDistributor && !isDistributor_khusus"
          class="mr-2 mt-2"
          v-model:value="kabupaten"
          v-model:area="area"
          v-model:provinsi="provinsi"
          v-model:region="region"
        ></filter-kabupaten-kota>
        <filter-distributor
          v-if="!isDistributor && !isDistributor_khusus"
          class="mr-2 mt-2"
          v-model:value="distributor"
          v-model:region="region"
          v-model:area="area"
          v-model:provinsi="provinsi"
          v-model:kabupaten="kabupaten"
        ></filter-distributor>
        <filter-brands class="mr-2 mt-2" v-model:value="brand" v-model:brand="brand" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <filter-products class="mr-2 mt-2" v-model:value="product" v-model:brand="brand" />
        <a-date-picker
          class="mr-2 mt-2"
          v-model:value="tanggal"
          placeholder="Pilih Tanggal"
          style="width: 300px"
          format="DD MMMM YYYY"
        />
        <a-input-search
          class="mr-2 mt-2"
          v-model:value="q"
          placeholder="Cari ..."
          style="width: 300px"
        />
        <a-button
          class="mr-2 mt-2"
          type="primary"
          title="cari"
          @click="search"
          :loading="isFetching"
        >
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button
          class="mr-3 mb-2"
          title="Download Excel"
          type="primary"
          @click="fetchXlsx"
          :loading="isDownloading"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Download ...</span>
        </a-button>
      </div>
    </div>

    <div class="table-responsive">
      <md-table
        :columns="columns"
        :data-source="data"
        size="small"
        row-key="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        bordered
        @change="handleTableChange"
        :loading="isFetching"
      >
        <template #no="{ index }">
          <span>
            {{ startRow + index }}
          </span>
        </template>
        <template #ribuan="{ text }">
          <span>{{ formatRibuan(text) }} </span>
        </template>
      </md-table>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  inject,
  computed,
  nextTick,
  onMounted,
  toRefs,
  onBeforeMount,
  provide,
  watch,
} from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterBrands from '@/components/filter/FilterBrand'
import FilterProducts from '@/components/filter/FilterProduct'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import acolumns from './columns.js'
import moment from 'moment'

import qs from 'qs'
import useUserInfo from '@/composables/useUserInfo'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  name: 'MFilter',
  components: {
    // MModal,
    FilterRegional,
    FilterProvince,
    FilterArea,
    FilterDistributor,
    FilterBrands,
    FilterProducts,
    FilterKabupatenKota,
  },
  setup() {
    const route = useRoute()
    const columns = ref([])
    const data = ref([])

    columns.value = acolumns

    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)

    const searchText = ref('')
    const searchInput = ref(null)
    const current1 = ref(1)
    const q = ref('')

    const region = ref([])
    const provinsi = ref([])
    const kabupaten = ref([])
    const product = ref([])
    const brand = ref([])
    const distributor = ref([])
    const tipe = ref(2)
    const { isDistributor, vendor_id, isDistributor_khusus } = useUserInfo()
    if (isDistributor.value || isDistributor_khusus.value) {
      distributor.value.push(vendor_id.value)
    }

    const area = ref([])
    const tanggal = ref();

    tanggal.value = moment(new Date()).subtract(1, 'days')

    const params = ref({})
    const errorMessage = ref(null)
    const fetchData = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
        region: region.value,
        area: area.value,
        provinsi: provinsi.value,
        kabupaten: kabupaten.value,
        product: product.value,
        brand: brand.value,
        distributor: distributor.value,
        date: tanggal.value.format('YYYY-MM-DD'),
        q: q.value,
      }
      params.value = Object.assign({}, _params)
      console.log(params.value);
      state.isFetching = true
      apiClient
        .get('/api/report/detail-gudang-distributor', {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          data.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }
    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/report/detail-gudang-distributor', {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined && c.export !== false)
              .map(({ dataIndex: key, title, title_xls }) => ({
                key,
                title: title_xls ? title_xls : title,
              })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `laporan-detail-gudang-distributor_${moment().format('DDMMYY_HHmm')}.xlsx`,
          )
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }
    const startRow = computed(() => (page.value - 1) * perPage.value + 1)
    watch(meta, (newValue, oldValue) => {
      page.value = newValue.currentPage
      perPage.value = newValue.perPage
    })
    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }
    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }
    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
      isDownloading: false,
    })

    const handleSearch = (selectedKeys, confirm) => {
      confirm()
      searchText.value = selectedKeys[0]
    }

    const handleReset = clearFilters => {
      clearFilters()
      searchText.value = ''
    }
    onMounted(() => {
      const { distri } = route.query
      if (distri) {
        if (!distributor.value.includes(Number(distri)))
          distributor.value.push(Number(distri))
      }
      fetchData()
    })

    const search = () => {
      fetchData()
    }

    const formatRibuan = value => {
      return new Intl.NumberFormat(
        'id-ID',
        { maximumFractionDigits: 2 },
        { minimumFractionDigits: 2 },
      ).format(Number(value))
    }

    return {
      q,
      searchText,
      searchInput,
      data,
      columns,
      handleReset,
      handleSearch,
      current1,
      perPage,
      totalCount,
      pageCount,
      page,
      meta,
      handleTableChange,
      startRow,
      region,
      provinsi,
      kabupaten,
      product,
      area,
      brand,
      distributor,
      onSelectChange,
      state,
      fetchXlsx,
      ...toRefs(state),
      tanggal,
      search,
      errorMessage,
      formatRibuan,
      isDistributor,
      isDistributor_khusus,
      tipe,
    }
  },
})
</script>
